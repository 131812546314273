import React, {createRef, useCallback, useEffect, useRef, useState} from 'react';
import GlobalTrans from "../../../helper/GlobalTrans";
import PropTypes from "prop-types";
import {Box} from "@material-ui/core";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import ImageUpload from "../../imageUpload/ImageUpload";
import Notifications from "../../notifications/Notifications";
import config from "../../../config/config";
import {helperCatchErrors} from "../../../helper/Helper";
import {useIntl} from "react-intl";
import {useStore} from "../../../store/useStore";

const ImageElement = (props) => {
    const {state} = useStore();
    const intl = useIntl();
    const elementName = GlobalTrans(props.title);

    const srcRef = useRef();
    const [imageUpload, setImageUpload] = useState(false);
    const [imageOriginal, setImageOriginal] = useState(props.src || '');
    const [src, setSrc] = useState('');
    const [uploadingImages, setUploadingImages] = useState(false);

    // Translations
    const prepareTranslations = () => {
        return state.languages.map(language => {
            let translation = props.translations.find(translation => translation['language'] === language['@id']);

            if (translation) {
                if (translation.src) {
                    translation.original = translation.src;
                }
            } else {
                translation = {
                    'language': language['@id'],
                    src: '',
                    original: ''
                }
            }

            translation.ref = createRef();
            translation.upload = false;

            return translation;
        });
    }

    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(prepareTranslations());

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl]);

    const showError = (errorMessage = '') => {
        setErrorMessage(errorMessage);
        window.scrollTo(0, 0);
        setNotificationError(true);
    }

    const resetNotifications = () => {
        setNotificationError(false);
        setNotificationSuccess(false);
    }

    const initSave = useCallback(() => {
        resetNotifications();

        srcRef.current.sendFile();

        translations.forEach((translation) => {
            translation.ref.current.sendFile();
        });

        setUploadingImages(true);
    }, [translations]);

    const isEveryItemImageUploaded = useCallback(() => {
        return translations.findIndex(translation => translation.upload === false) === -1;
    }, [translations]);

    const saveModal = useCallback(() => {
        // create data
        const data = {
            src,
            translations,
        };

        // update the data
        props.updateContent(props, data);
    }, [props, src, translations]);

    useEffect(() => {
        if (uploadingImages && imageUpload && isEveryItemImageUploaded) {
            setUploadingImages(false);

            saveModal();
        }
    }, [uploadingImages, imageUpload, isEveryItemImageUploaded, saveModal]);

    const showOriginalImage = (original) => {
        if (original && original?.contentUrl) {
            return (
                <Box mt={1}>
                    {
                        original?.contentUrl &&
                        <img className={'img-fluid'} src={config.apiHost + original.contentUrl} alt={''}/>
                    }
                </Box>
            );
        }

        return null;
    }

    const updateTranslations = (translation, field, value) => {
        const newTranslations = [...translations];
        const findTranslation = newTranslations.find(needleTranslation => needleTranslation['language'] === translation['language']);

        if (findTranslation) {
            findTranslation[field] = value;

            setTranslations(newTranslations);
        }
    }

    const renderImageUploads = () => {
        return (
            <>
                <Box display={!translationLanguage ? 'block' : 'none'}>
                    <ImageUpload
                        ref={srcRef}
                        setImageIRI={(value) => {
                            setSrc(value);
                            setImageOriginal(value);
                        }}
                        showError={showError}
                        catchErrors={catchErrors}
                        setUploadStatus={setImageUpload}
                        originalIRI={imageOriginal?.['@id']}
                        returnType={'object'}
                    />
                    {showOriginalImage(imageOriginal)}
                </Box>
                {
                    translations.map((translation) => (
                        <Box display={translationLanguage === translation.language ? 'block' : 'none'}
                             key={translation.language}>
                            <ImageUpload
                                ref={translation.ref}
                                setImageIRI={(value) => {
                                    updateTranslations(translation, 'src', value);
                                }}
                                showError={showError}
                                catchErrors={catchErrors}
                                setUploadStatus={(value) => updateTranslations(translation, 'upload', value)}
                                originalIRI={translation?.original['@id']}
                                returnType={'object'}
                            />
                            {showOriginalImage(translation?.original)}
                        </Box>
                    ))
                }
            </>
        );
    };

    const modalBody = (
        <Box mb={2}>
            <Notifications
                success={notificationSuccess}
                error={notificationError}
                errorMessage={errorMessage}
            />
            {renderImageUploads()}
        </Box>
    );

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={initSave}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                setTranslationLanguage={setTranslationLanguage}
                translationLanguage={translationLanguage}
            >
                {modalBody}
            </ModuleElementActions>
            {
                props.src?.contentUrl &&
                <img className={'img-fluid'} src={config.apiHost + props.src.contentUrl} alt={''}/>
            }
        </Box>
    );
};

ImageElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
}

// Default config for the index.js array
export const ImageElementConfig = {
    title: 'image',
    src: '',
    translations: [],
}

export default ImageElement;